<template>
  <div class="navbar-container shadow navbar-header">
    <div class="navbar bg-white">
      <div class="container-fluid">
        <div class="d-flex">
          <div
            v-if="$route.meta && $route.meta.showBackIcon"
            class="el-page-header__left"
            @click="$r.goBack"
          >
            <div class="el-page-header__icon"><i class="el-icon-back"></i></div>
          </div>
          <div class="el-page-header__content header-ttl">
            {{
              $route.meta.menu && $route.meta.menu == "settings"
                ? $t.translate("Setting")
                : $t.translate($route.meta.title)
            }}
          </div>
        </div>

        <div class="order-lg-2 justify-content-lg-end">
          <ul class="navbar-nav my-lg-0 align-items-center" style="flex-direction: row">
            <li class="nav-item d-flex align-items-center">
              <div
                class="d-none d-md-flex mr-3 c-pointer"
                @click="$r.changeRoute('/home')"
              >
                <span class="f-text-orange text-underline">{{
                  $t.translate("FP_FOCALPOINT")
                }}</span>
              </div>

              <div
                class="d-flex mr-3"
                v-if="$h.hasRoles(['CLIENT', 'SUB_CLIENT']) && !$h.isSmall()"
              >
                <span>{{ $h.formatOrgBranch(userData?.Organization) }}</span>
              </div>

              <!-- <div v-if="$route.meta?.AllowExport == true" class="d-flex mr-3">
                <el-button size="small" @click="exportList">
                  <i class="el-icon-download"></i
                  ><span>{{ $t.translate("BTN_EXPORT", "Export") }}</span>
                </el-button>
              </div> -->

              <el-dropdown
                class="mr-3"
                trigger="click"
                :hide-on-click="true"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-user" style="font-size: 24px"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="/password">{{
                      $t.translate("TTL_CHANGE_PASSWORD")
                    }}</el-dropdown-item>
                    <el-dropdown-item command="/profile">{{
                      $t.translate("TTL_MY_PROFILE")
                    }}</el-dropdown-item>
                    <el-dropdown-item command="/logout">{{
                      $t.translate("TTL_LOGOUT")
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              <el-dropdown
                class="nav-item d-769-none mr-3"
                trigger="click"
                :hide-on-click="true"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-menu" style="font-size: 24px"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <template v-for="item in navigationLinks" :key="item.Path">
                      <el-dropdown-item
                        v-if="$h.hasRoles(item.Role)"
                        :command="item.Path"
                        >{{ $t.translate(item.Name) }}</el-dropdown-item
                      >
                    </template>

                    <el-dropdown-item command="/home">
                      <span class="f-text-orange text-underline">{{
                        $t.translate("FP_FOCALPOINT")
                      }}</span>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item command="help">Help</el-dropdown-item> -->
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              <el-dropdown trigger="click" :hide-on-click="true" @command="changeLang">
                <span class="el-dropdown-link">
                  <!--<i class="fas fa-language" style="font-size: 22px"></i>-->
                  <span class="c-pointer">{{
                    $configs.langList.find((it) => it.code == $t.getLanguage()).name
                  }}</span>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="lang in $configs.langList"
                      :key="lang.code"
                      :command="lang.code"
                      >{{ $t.translate(lang.name) }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <template
      v-if="$route.path == '/applications' && !$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
    >
      <el-scrollbar wrap-class="container-fluid bg-white">
        <div class="d-flex">
          <div
            v-for="item in StatusList"
            :key="item.value"
            class="swipe-tab"
            :class="{
              'active-tab':
                $route.query.status == item.value ||
                (!$route.query.status && item.value == 'ALL'),
            }"
            @click="handleSwipe(item.value)"
          >
            <span>{{ $t.translate(item.label) }}</span>
          </div>
        </div>
      </el-scrollbar>
    </template>

    <template
      v-if="$route.path == '/generated_certs' && !$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
    >
      <el-scrollbar wrap-class="container-fluid bg-white">
        <div class="d-flex">
          <div
            v-for="item in CertStatusList"
            :key="item.value"
            class="swipe-tab"
            :class="{
              'active-tab':
                $route.query.status == item.value ||
                (!$route.query.status && item.value == 'ALL'),
            }"
            @click="handleCertSwipe(item.value)"
          >
            <span>{{ $t.translate(item.label) }}</span>
          </div>
        </div>
      </el-scrollbar>
    </template>

    <template v-if="$route.meta.menu && $route.meta.menu == 'settings'">
      <el-scrollbar wrap-class="container-fluid bg-white">
        <div class="d-flex">
          <div
            v-for="item in SettingList"
            :key="item.value"
            class="swipe-tab"
            :class="{ 'active-tab': $route.path == item.path }"
            @click="handleSelect(item.path)"
          >
            <span>{{ $t.translate(item.label) }}</span>
          </div>
        </div>
      </el-scrollbar>
    </template>
  </div>
</template>
<script>
import { extendedConfigs } from "@/utils/configs";
import { store } from "@/store";
import { useRoute } from "vue-router";
import { onUpdated, onMounted, ref } from "vue";
import { router } from "@/utils/router";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  setup() {
    let { helpText, helpTitle } = extendedConfigs;

    const route = useRoute();
    const userData = store.getters["user/getData"]?.UserData || {};

    let StatusList = [
      { label: "LBL_ALL", value: "ALL" },
      { label: "LBL_DRAFT", value: "INPROGRESS" },
      { label: "LBL_CONTRACT_REVIEW", value: "PENDING" },
      { label: "LBL_UNDERREVIEW", value: "UNDERREVIEW" },
      { label: "LBL_RETURNED", value: "REJECTED" },
      { label: "LBL_REJECTED_STAFF", value: "REJECTED_STAFF" },
      { label: "LBL_CANCELLED", value: "CANCELLED" },
      { label: "LBL_ARCHIVED", value: "ARCHIVED" },
      { label: "LBL_APPROVED", value: "CERTIFIED" },
      { label: "LBL_PUBLISHED", value: "COMPLETED" },
    ];

    let CertStatusList = [
      { label: "LBL_ALL", value: "ALL" },
      { label: "LBL_APPROVED", value: "PENDING" },
      { label: "LBL_CERTIFIED", value: "CERTIFIED" },
      { label: "LBL_VERIFIED", value: "VERIFIED" },
      { label: "LBL_EXPIRED", value: "EXPIRED" },
      //{ label: "LBL_RENEWAL", value: "RENEWAL" },
      { label: "LBL_SUSPENDED", value: "SUSPENDED" },
      { label: "LBL_TERMINATED", value: "TERMINATED" },
    ];

    let SettingList = [
      //{ label: "TTL_STRIPE_CONNECT", value: "stripe", path: "/stripe" },
      { label: "TTL_STAFF_ACCOUNT", value: "staffs", path: "/staffs" },
      { label: "TTL_BUSINESS_TEAM", value: "business_teams", path: "/business_teams" },
      { label: "TTL_SERVICE_TAGS", value: "business_teams", path: "/tags" },
      { label: "TTL_TRANSLATIONS", value: "translation", path: "/translation" },
      { label: "LBL_COUNTRY_LIST", value: "country", path: "/country" },
      // {
      //   label: "TTL_EMAIL_NOTIFICATION",
      //   value: "email_notification",
      //   path: "/email_notification",
      // },
    ];

    const handleCommand = (comd) => {
      if (comd == "help") {
        ElMessageBox.alert(
          translation.translate(helpText),
          translation.translate(helpTitle),
          {
            confirmButtonText: "OK",
          }
        );
        return;
      }
      router.changeRoute(comd);
    };

    const handleSwipe = (val) => {
      router.replaceRoute("/applications", { status: val });
    };

    const handleCertSwipe = (val) => {
      router.replaceRoute("/generated_certs", { status: val });
    };

    const handleSelect = (path) => {
      router.replaceRoute(path);
    };

    const exportList = () => {
      /* waiting for api integration */
    };

    const changeLang = async (val) => {
      await store.dispatch("translation/setData", { languageCode: val });
    };

    let { navigationLinks } = extendedConfigs;

    return {
      userData,
      StatusList,
      CertStatusList,
      SettingList,
      navigationLinks,
      handleCommand,
      handleSwipe,
      handleCertSwipe,
      handleSelect,
      exportList,
      changeLang,
    };
  },
};
</script>
